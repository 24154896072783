.rich-text-editor {
  max-height: 200px; /* Adjust this value to your desired max height */
  overflow-y: auto;
}

.rich-text-editor .ql-editor {
  min-height: 150px; /* Adjust this value to your desired max height */
}

.ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.small-editor.active > .ql-toolbar {
  border-bottom-color: #ccc;
  border-top-color: transparent;
  border-right-color: #0b7373;
  border-left-color: #0b7373;
}

.small-editor.active > .ql-container {
  border-bottom-color: #0b7373;
  border-top-color: #ccc;
  border-right-color: #0b7373;
  border-left-color: #0b7373;
}

.small-editor .ql-container {
  max-height: 250px; /* Adjust this value to your desired max height */
  overflow-y: auto;
  flex: 1 1 0%;
}

.small-editor .ql-toolbar {
  border-top-width: 0 !important;
}

.question-editor .ql-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.question-editor .ql-container {
  max-height: 450px; /* Adjust this value to your desired max height */
  overflow-y: auto;
}
