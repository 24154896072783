@tailwind base;
@tailwind components;
@tailwind utilities;
/* @tailwind variants; */

html,
body {
  scroll-behavior: smooth;
}

*,
p {
  font-family: var(--font-inter), sans-serif;
  font-weight: 400;
}

p {
  @apply text-ink;
}

a {
  @apply text-info-light cursor-pointer;
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.loading {
  @apply relative overflow-hidden pointer-events-none animate-pulse;
}

.loading:before,
.loading:after {
  content: '';
  @apply inset-0 absolute;
}

.loading:before {
  z-index: 2;
  @apply bg-grey;
}

input[disabled] {
  pointer-events: none;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.wave-container {
  @apply relative;
}

.wave-container::before {
  content: '';
  width: 100%;
  height: 25px;
  position: absolute;
  bottom: -0.3%;
  left: 0;
  background-size: auto;
  background-repeat: repeat no-repeat;
  background-position: 41vw bottom;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  80' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 59L50 55C100 51 200 44 300 30C400 15 500 -6 600 1C700 8 800 44 900 59C1000 73 1100 66 1150 62L1200 59V80H1150C1100 80 1000 80 900 80C800 80 700 80 600 80C500 80 400 80 300 80C200 80 100 80 50 80H0V59Z' fill='%23FFFFFF'/></svg>");
}

.wave-grey::before {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  80' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 59L50 55C100 51 200 44 300 30C400 15 500 -6 600 1C700 8 800 44 900 59C1000 73 1100 66 1150 62L1200 59V80H1150C1100 80 1000 80 900 80C800 80 700 80 600 80C500 80 400 80 300 80C200 80 100 80 50 80H0V59Z' fill='%23F2F4F5'/></svg>");
}

.wave-primary::before {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  80' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 59L50 55C100 51 200 44 300 30C400 15 500 -6 600 1C700 8 800 44 900 59C1000 73 1100 66 1150 62L1200 59V80H1150C1100 80 1000 80 900 80C800 80 700 80 600 80C500 80 400 80 300 80C200 80 100 80 50 80H0V59Z' fill='%230B7373'/></svg>");
}

@media (max-width: 850px) {
  .wave-container::before {
    height: 12.5px;
  }
}
